import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popconfirm, Select, Space } from 'antd';
import moment from 'moment'

import DefaultLayout from '../../components/layouts/DefaultLayout';
import DefaultTable from '../../components/common/tables/DefaultTable';
import TableHeader from '../../components/common/tables/TableHeader';
import ArticleAddOrEditForm from '../../components/articles/ArticleAddOrEditForm';
import DefaultPagination from '../../components/common/Pagination';

import { createArticle, deleteArticle, getArticles, updateArticle } from '../../reducers/articles/actions';
import { getAuthors } from '../../reducers/authors/actions';
import { ARTICLE_STATUS, CATEGORIES } from '../../constants/constants';
import { findCategoryArticleName } from '../../utils/getCategories';
import { findStatusArticleName } from '../../utils/getStatusArticles';
import pageChanger from '../../utils/pageChanger';


const ArticlesPage = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles.get('articles'));
  const authors = useSelector((state) => state.authors.get('authors').toJS());
  const loading = useSelector((state) => state.articles.get('loading'));
  const totalCount = useSelector((state) => state.articles.getIn(['pagination', 'totalCount']));
  const pageCount = useSelector((state) => state.articles.getIn(['pagination', 'pageCount']));
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [editingObject, setEditingObject] = useState(null);

  useEffect(() => {
    dispatch(getArticles({page: currentPage, limit}));
    dispatch(getAuthors({page: 1, limit: 9999}));
  }, [dispatch, currentPage, limit]);

  useEffect(() => {
    pageChanger(articles.size, getArticles, currentPage, limit, pageCount, setCurrentPage, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles.size]);

  const onUpdateArticle = (id, index, values) => {
    dispatch(updateArticle(id, index, values));
  };

  const onDeleteArticle = (id, index) => {
    dispatch(deleteArticle(id, index));
  };

  const onAddArticle = (article) => {
    console.log('onAddArticle - ', article);
    dispatch(createArticle(article));
  };

  const onShowEditingWindow = (index, article) => {
    setEditingObject({ ...article, index });
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const categoriesOptions = CATEGORIES.map((category) => {
    return <Select.Option value={category.key}>{category.title}</Select.Option>;
  });

  const authorsOptions = authors.map((author) => {
    return <Select.Option value={author.id}>{author.firstName}&nbsp;{author.lastName}</Select.Option>;
  });

  const statusesOptions = ARTICLE_STATUS.map((status) => {
    return <Select.Option value={status.value}>{status.text}</Select.Option>;
  });

  const searchAuthor = (id) => {
    const currAuthor  = authors.find(author => author.id === id);
    return <>{currAuthor?.firstName}&nbsp;{currAuthor?.lastName}</>
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (_, item) => {
        return <div style={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }}>{item.title}</div>;
      },
    },
      
    {
      title: 'Category',
      dataIndex: 'category',
      render: (_, item) => {
        return <>{`${findCategoryArticleName(item?.category)}`}</>
      },
    },

    {
      title: 'Author',
      dataIndex: 'authorID',
      render: (_, item) => {
        return searchAuthor(item.authorID)
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, item) => {
        return findStatusArticleName(item.status)
      },
      filters: ARTICLE_STATUS,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },

    {
      title: 'Last update',
      dataIndex: '_lastChangedAt',
      render: (_, item) => {
        return <>{moment(item._lastChangedAt).format("DD.MM.YYYY HH:MM")}</>
      },
    }
  ];

  const actionColumn = {
    title: 'Actions',
    width: '10%',
    render: (_, item, index) => {
      return (
        <Space size="middle">
          <Button type="link" onClick={() => onShowEditingWindow(index, item)}>Edit</Button>
          <Popconfirm title="Delete this item?" onConfirm={() => onDeleteArticle(item.id, index)}>
          <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      );
    },
  };

  return (
    <DefaultLayout>
      <TableHeader 
        title="Articles"
        onAdd={onAddArticle}
        onUpdate={onUpdateArticle}
        drawerWidth={900}
        editingItem={editingObject}
        setEditingItem={setEditingObject}
      >
        <ArticleAddOrEditForm 
          categories={categoriesOptions}
          authors={authorsOptions}
          statuses={statusesOptions}
          editingItem={editingObject} 
        />
      </TableHeader>
      <DefaultTable
        data={articles}
        loading={loading}
        columns={columns}
        actionColumn={actionColumn}
      />
      <DefaultPagination
        totalCount={totalCount}
        currentPage={currentPage}
        onChange={onPageChange}
      />
    </DefaultLayout>
  )
}

export default ArticlesPage;