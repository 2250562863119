import React, { useEffect, useState } from 'react';
import {useRef} from 'react';

import { Form, Row, Col, Input, Select, InputNumber, Button, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import uploadPlugin from '../../common/UploadPlugin';

import { createNewFile } from '../../../services/storage';

import './index.scss'
const { TextArea } = Input;

const ArticleAddOrEditForm = ({ form, categories, authors, statuses, editingItem }) => {

  const inputRef = useRef(null);
  const [ loading, setLoading ] = useState(false);
  const [ fileURL, setFileURL ] = useState('');
  const [ altNamePreview, setAltNamePreview ] = useState('');

  useEffect(() => {
    // if (editingItem) {
    //   setFileURL('')
    //   setAltNamePreview('')
    // }
    form.resetFields();
    setFileURL(editingItem?.preview?.url)
    setAltNamePreview(editingItem?.preview?.altName)
    console.log('editingItem - ', editingItem);
    form.setFieldsValue({
      preview: {
        url: editingItem?.preview?.url,
        altName: editingItem?.preview?.altName
      } 
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const handleFileChange = async (e) => {
    setLoading(true)
    const response =  await createNewFile({
      file: e.currentTarget.files[0], 
      contentType: e.currentTarget.files[0].type ,
      type: 'ARTICLE'
    })
    if (response) {
      setFileURL(response?.url)
      form.setFieldsValue({
        preview: {
          url: response?.url,
          altName: altNamePreview
        } 
      })
      setLoading(false)
    }
  }  

  const handleClick = () => {
    inputRef.current.click();
  };

  const onChangeAltNamePreview = (event) => {
    setAltNamePreview(event.target.value)
    form.setFieldsValue({
      preview: {
        url: fileURL,
        altName: event.target.value
      } 
    })
  }

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="title"
            label="Title"
            initialValue={editingItem?.title}
            rules={[{
              required: true,
              // pattern: /^[\w\s._-]{2,2000}$/,
              // message: 'The title should contain only English letters and be from 2 to 200 characters long',
            }]}
          >
            <Input
              placeholder="Please enter Title"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="url"
            label="URL"
            initialValue={editingItem?.url}
            rules={[{
              required: true,
              // pattern: /^[\w\s._-]{2,2000}$/,
              // message: 'The title should contain only English letters and be from 2 to 20000 characters long',
            }]}
          >
            <Input
              placeholder="Please enter url"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="category"
            label="Categories"
            initialValue={editingItem ? editingItem.category : 'client_guides'}
            rules={[{
              required: true,
              // pattern: /^[\w\s._-]{2,2000}$/,
              // message: 'The title should contain only English letters and be from 2 to 20000 characters long',
            }]}
          >
            <Select>
              {categories}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="authorID"
            label="Author"
            initialValue={editingItem?.authorID}
            rules={[{
              required: true,
              // pattern: /^[\w\s._-]{2,2000}$/,
              // message: 'The title should contain only English letters and be from 2 to 20000 characters long',
            }]}
          >
            <Select>
              {authors}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="status"
            label="Status"
            initialValue={editingItem ? editingItem.status : 'PUBLISHED'}
            rules={[{
              required: true,
              // pattern: /^[\w\s._-]{2,2000}$/,
              // message: 'The title should contain only English letters and be from 2 to 20000 characters long',
            }]}
          >
            <Select>
              {statuses}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="readTime"
            label="Read time (min)"
            initialValue={editingItem ? editingItem.readTime : 1}
            rules={[{ required: true, message: 'Please select read time' }]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
      <Col span={24}>
        <Form.Item 
          label='Article text' 
          name='content'
          valuePropName='data'
          getValueFromEvent={(event, editor) => {
            const data = editor.getData();
            return data;
          }}
          initialValue={editingItem ? editingItem.content : ''}
          rules={[{ required: false, message: 'Please enter article text' }]}
        >
          <CKEditor 
            editor={ClassicEditor} 
            config={{
              extraPlugins: [uploadPlugin]
            }}
          />
        </Form.Item>
      </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="description"
            label="Description"
            initialValue={editingItem?.description}
            rules={[{ max: 250 }]}
          >
            <TextArea 
              autoSize={{ minRows: 5, maxRows: 5 }}
              placeholder="Please enter description" 
              showCount 
              maxLength={250}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="preview"
            label="Preview"
            // initialValue={editingItem?.preview}
            // rules={[{
            //   required: false,
            //   pattern: /^[\w\s._-]{2,2000}$/,
            //   message: 'The title should contain only English letters and be from 2 to 200 characters long',
            // }]}
          >
            {fileURL && <Image width={400} src={fileURL} />}
            <input
              style={{display: 'none'}}
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
            <Col style={{paddingTop: '10px'}}>
              <Button 
                icon={<UploadOutlined />} 
                loading={loading}
                disabled={loading}
                onClick={handleClick}
              >
                { fileURL ? 'Select preview' : 'Select new preview' }
              </Button>
            </Col>
            {/* <Input
              placeholder="Please enter preview"
            /> */}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            label="Alt name preview"
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20000 characters long',
            }]}
          >
            <Input
              placeholder="Please enter alt name preview"
              value={altNamePreview}
              onChange={onChangeAltNamePreview}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ArticleAddOrEditForm;
