import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Typography } from 'antd';

import Spinner from '../../components/common/Spinner';
import LoginForm from '../../components/loginPage/LoginForm';

import { login } from '../../reducers/auth/actions';
import sendEmailWithRecoveryLink from '../../reducers/passwords/actions';

import './index.scss';

const LoginPage = ({ loading, dispatch }) => {
  const [forgotPassword, setForgotPassword] = useState(false);

  const onFinish = (values) => {
    if (forgotPassword) return dispatch(sendEmailWithRecoveryLink(values));
    dispatch(login(values));
  };
  
  const linkName = forgotPassword ? 'Back' : 'Forgot your password?';

  if (loading) return <Spinner />;

  return (
    <div className="login-page">
      <Typography.Title className="login-page__logo">ESTENX Admin Panel</Typography.Title>
      <LoginForm onFinish={onFinish} forgotPassword={forgotPassword} />
      <Typography.Link onClick={() => setForgotPassword(!forgotPassword)}>
        {linkName}
      </Typography.Link>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.get('loading'),
});

export default connect(mapStateToProps)(LoginPage);
