import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, } from '@ant-design/icons';

import SideMenu from '../../common/SideMenu';

import './index.scss';

const DefaultLayouts = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [broken, setBroken] = useState(true);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onBreakpoint = (isBroken) => {
    setBroken(isBroken);
  };

  return (
    <Layout className="default-layout">
      <Layout.Header 
        className="default-layout__header"       
        style={{
          display: 'flex',
          alignItems: 'center',
          lignItems: 'center',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}>
        {/* <span className="default-layout__header-logo" style={{ width: '200px' }}>ESTEN</span> */}
        {!collapsed ?     
        <Link to="/" className="default-layout__header-logo" style={{ width: '200px' }}>
          <div style={{ paddingLeft: '45px' }}>ESTENX</div>
        </Link>
        :
        <Link to="/" className="default-layout__header-logo" style={{ width: '78px' }}>
          <div style={{ paddingLeft: '8px' }}>E</div>
        </Link>}
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'default-layout__header-trigger',
          onClick: onCollapse,
        })}
        <Link to="/" className="default-layout__header-logo">
          <span className="default-layout__header-logo">Admin Panel</span>
        </Link>
      </Layout.Header>

      <Layout>
        <SideMenu
          className="default-layout__sider"
          collapsed={collapsed}
          broken={broken}
          onCollapse={onCollapse}
          onBreakpoint={onBreakpoint}
        />

        <Layout 
          className="default-layout__content"       
          style={{
            marginTop: 64,
            marginLeft: collapsed ? 80 : 200,
          }}
        >
          <Layout.Content>
            {children}
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DefaultLayouts;
