import { ALL_DATA_LIMIT, FIRST_PAGE } from '../constants/constants'
import api from '../services'

export const getAllAuthors = async function({ limit = ALL_DATA_LIMIT, page = FIRST_PAGE, result = [] }) {
  const authors = await api.getAuthors({page, limit})

  let isRepeat = true
  let resultIds = result.map(author => author.id)
  for (let author of authors) {
    if (!resultIds.includes(author.id)) {
      isRepeat = false
      result.push(author)
    }
  }
  if (!isRepeat) {
    page++
    await getAllAuthors({ limit, page, result });
  }
  return result;
}

export const getAllJobs = async function({ limit = ALL_DATA_LIMIT, page = FIRST_PAGE, result = [] }) {
  const jobs = await api.getJobs({page, limit})

  let isRepeat = true
  let resultIds = result.map(job => job.id)
  for (let job of jobs) {
    if (!resultIds.includes(job.id)) {
      isRepeat = false
      result.push(job)
    }
  }
  if (!isRepeat) {
    page++
    await getAllAuthors({ limit, page, result });
  }
  return result;
}

export async function getAllPosts({ limit = ALL_DATA_LIMIT, category, page = FIRST_PAGE, result = [] }) {
  let posts
  if (category) 
    posts = await api.getPostsByCategory({category, page, limit})
  else 
    posts = await api.getPosts({page, limit})

  let isRepeat = true
  let resultIds = result.map(post => post.id)
  for (let post of posts) {
    if (!resultIds.includes(post.id)) {
      isRepeat = false
      result.push(post)
    }
  }

  if (!isRepeat) {
    page++
    await getAllPosts({ limit, category, page, result });
  }
  return result;
}

export async function getAllUsers({ limit = ALL_DATA_LIMIT, token = undefined, result = []}) {
  const users =  await api.getAllUsers({limit, token})

  let isRepeat = true
  let resultIds = result.map(user => user.id)
  for (let user of users?.Users) {
    if (!resultIds.includes(user.id)) {
      isRepeat = false
      result.push(user)
    }
  }

  // console.log("TOKEN - ", users?.NextToken)
  if (!isRepeat && users?.NextToken) {
    const nextToken = users.NextToken;
    await getAllUsers({ limit, token: nextToken, result });
  }
  return result;
}

export async function getAllUserGroups({ userEmail, limit = ALL_DATA_LIMIT, token = undefined, result = []}) {
  const groups =  await api.getUserGroups({userEmail,limit, token})

  let isRepeat = true
  let resultIds = result.map(group => group.id)
  for (let group of groups?.Groups) {
    if (!resultIds.includes(group.id)) {
      isRepeat = false
      result.push(group)
    }
  }
  
  // console.log("TOKEN - ", groups?.NextToken)
  if (!isRepeat && groups?.NextToken) {
    const nextToken = groups.NextToken;
    await getAllUserGroups({ userEmail, limit, token: nextToken, result });
  }
  return result;
}

export async function getAllUsersByGroup({ group, limit = ALL_DATA_LIMIT, token = undefined, result = []}) {
  const users =  await api.getAllUsersByGroup({group,limit, token})

  let isRepeat = true
  let resultIds = result.map(user => user.id)
  for (let user of users?.Users) {
    if (!resultIds.includes(user.id)) {
      isRepeat = false
      result.push(user)
    }
  }
  
  // console.log("TOKEN - ", users?.NextToken)
  if (!isRepeat && users?.NextToken) {
    const nextToken = users.NextToken;
    await getAllUsersByGroup({ group, limit, token: nextToken, result });
  }
  return result;
}

export async function getAllGroups({limit = ALL_DATA_LIMIT, token = undefined, result = []}) {
  const groups =  await api.getAllGroups({limit, token})

  let isRepeat = true
  let resultIds = result.map(group => group.id)
  for (let group of groups?.Groups) {
    if (!resultIds.includes(group.id)) {
      isRepeat = false
      result.push(group)
    }
  }
  
  // console.log("TOKEN - ", groups?.NextToken)
  if (!isRepeat && groups?.NextToken) {
    const nextToken = groups.NextToken;
    await getAllGroups({ limit, token: nextToken, result });
  }
  return result;
}