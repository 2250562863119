import React from 'react';

import { Input, AutoComplete } from 'antd';

const Search = ({ search }) => {
  const [onSearchCancel, onSearchChange] = [...search];

  const onChange = (value) => {
    if (!value) {
      onSearchCancel();
    } else {
      onSearchChange(value);
    }
  };

  return (
    <AutoComplete
      onSearch={onChange}
    >
      <Input
        placeholder="Search route"
        allowClear
        style={{ width: 400 }}
      />
    </AutoComplete>
  );
};

export default Search;