import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'antd';

import DefaultLayout from '../../components/layouts/DefaultLayout';
import DefaultTable from '../../components/common/tables/DefaultTable';
import TableHeader from '../../components/common/tables/TableHeader';
import FilesAddForm from '../../components/files/FileAddForm';

import { getFiles, updateFile, deleteFile, createFile } from '../../reducers/files/actions';
import DefaultPagination from '../../components/common/Pagination';
import pageChanger from '../../utils/pageChanger';

const FilesPage = () => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.files.get('files'));
  const loading = useSelector((state) => state.files.get('loading'));
  const totalCount = useSelector((state) => state.files.getIn(['pagination', 'totalCount']));
  const pageCount = useSelector((state) => state.files.getIn(['pagination', 'pageCount']));
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    dispatch(getFiles(currentPage, limit));
  }, [dispatch, currentPage, limit]);

  useEffect(() => {
    pageChanger(files.size, getFiles, currentPage, limit, pageCount, setCurrentPage, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.size]);

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const onUpdateFile = (id, index, values) => {
    dispatch(updateFile(id, index, values));
  };

  const onDeleteFile = (id, index) => {
    dispatch(deleteFile(id, index));
  };

  const onAddFile = (file) => {
    dispatch(createFile(file));
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      component: <Input />,
      rules: [{ required: true, pattern: /^[\w\s._-]{2,100}$/ }]
    },
      
    {
      title: 'Route',
      dataIndex: 'route',
      component: <Input />,
      rules: [{ 
        required: true,
        pattern: /^[0-9a-z._/-]{2,120}$/,
        message: 'Please use only numbers, lower case latin letters or symbols . - _',
      }]
    },
    
    {
      title: 'Date',
      dataIndex: 'createdAt',
      rules: [{ required: true }],
    },

    {
      title: 'Link',
      dataIndex: 'link',
      width: '6%',
      render: (_, file) => {
        return <a href={file.link} target="_blank" rel="noreferrer">Open</a>;
      }
    }
  ];

  return (
    <DefaultLayout>
      <TableHeader title="Files" onAdd={onAddFile}>
        <FilesAddForm />
      </TableHeader>
      <DefaultTable
        data={files}
        loading={loading}
        columns={columns}
        onDelete={onDeleteFile}
        onEdit={onUpdateFile}
      />
      <DefaultPagination
        totalCount={totalCount}
        currentPage={currentPage}
        onChange={onPageChange}
      />
    </DefaultLayout>
  );
};

export default FilesPage;