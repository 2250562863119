// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const MediaType = {
  "AVATAR": "AVATAR",
  "ARTICLE": "ARTICLE"
};

const Status = {
  "DRAFT": "DRAFT",
  "HIDE": "HIDE",
  "PUBLISHED": "PUBLISHED"
};

const { Category, Media, Author, Post, CategoryItem, Rating, Preview } = initSchema(schema);

export {
  Category,
  Media,
  Author,
  Post,
  MediaType,
  Status,
  CategoryItem,
  Rating,
  Preview
};