import { Auth } from 'aws-amplify';

import { message } from 'antd';

import authTypes from './authTypes';

export const initialAuth = () => async (dispatch) => {
  dispatch({ type: authTypes.AUTH__START });
  Auth.currentAuthenticatedUser()
    .then(( user ) => {
      // console.log(user.attributes.email);
      // console.log(user.signInUserSession.accessToken.payload['cognito:groups'][0]);
      return dispatch({
        type: authTypes.AUTH__SUCCESS,
        payload: {
          email: user.attributes.email,
          role: user.signInUserSession.accessToken.payload['cognito:groups'][0]
        },
      });
    })
    .catch(() => {
      return dispatch({ type: authTypes.AUTH__FAILED });
    });
};

export const login = ({ email, password }) => async (dispatch) => {
  dispatch({ type: authTypes.AUTH__START });
  try {
    await Auth.signIn(email, password);
    const user = await Auth.currentAuthenticatedUser();
    console.log(user);
    return dispatch({
      type: authTypes.AUTH__SUCCESS,
      payload: {
        email: user.attributes.email,
        role: user.signInUserSession.accessToken.payload['cognito:groups'][0]
      },
    });
  } catch (err) { 
    console.log({ err }); 
    message.warning(err);
    return dispatch({ type: authTypes.AUTH__FAILED });
  }
};

export const signOutAuth = () => async (dispatch) => {
  try {
    await Auth.signOut({ global: true });
    return dispatch({ type: authTypes.AUTH__FAILED });
  } catch (error) {
    return dispatch({ type: authTypes.AUTH__FAILED });
  }
}
