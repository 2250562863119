import { CATEGORIES, PROGRAMMING_JOBS } from "../constants/constants";

export const findCategoryJobName = (key) => {
  // eslint-disable-next-line no-undef
  const currCategory = PROGRAMMING_JOBS.find(category => category.key === key);
  return currCategory?.title;
};

export const findCategoryArticleName = (key) => {
  // eslint-disable-next-line no-undef
  const currCategory = CATEGORIES.find(category => category.key === key);
  return currCategory?.title;
};