import usersTypes from './usersTypes';
import api from '../../services'
import { FIRST_PAGE, PAGE_LIMIT } from '../../constants/constants';
import { getAllGroups, getAllUserGroups, getAllUsers, getAllUsersByGroup } from '../../utils/getAllData';
import { getRemovedAddedGroups } from '../../utils/getGroups';

export const getUsers = ({page = FIRST_PAGE, limit = PAGE_LIMIT, filter }) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const users = await getAllUsers({})
    let result = users
    if (filter) {
      const re = new RegExp(filter.toUpperCase(), 'g')
      result = users.filter(user => {
        const idxName = user.Attributes.findIndex(attr => attr.Name === 'name')
        const idxEmail = user.Attributes.findIndex(attr => attr.Name === 'email')
        const idxFamyliName = user.Attributes.findIndex(attr => attr.Name === 'family_name')
        return (
          user.Attributes?.[idxName]?.Value?.toUpperCase().match(re) ||  
          user.Attributes?.[idxEmail]?.Value?.toUpperCase().match(re) || 
          user.Attributes?.[idxFamyliName]?.Value?.toUpperCase().match(re)
        )
      })
    }
    const pageCount =  Math.ceil(result.length / limit)
    const totalCount = result.length
    result = result.splice(limit * (page - 1), limit * (page - 1) + limit)
    result = result.map(user => user.Attributes.reduce((acc, curr) => {
        if (curr.Name === 'name')
          acc.firstName = curr.Value
        if (curr.Name === 'family_name')
          acc.lastName = curr.Value
        if (curr.Name === 'email')
          acc.userEmail = curr.Value
        if (curr.Name === 'custom:groups')
          acc.groups = curr.Value.split(',')
      return acc
    }, {}))
    // console.log(result)
    return dispatch({
      type: usersTypes.GET_LIST_USERS,
      payload: {
        users: result,
        pagination: {
          page,
          pageCount,
          totalCount
        },
      },
    });
  } catch (err) {
    console.log("GET USERS ERROR - ", err)
    return dispatch({ type: usersTypes.USERS_ACTION_FAILED });
  }  
};

export const createUser = ({userEmail, firstName, lastName, role}) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const user = await api.createUser({userEmail, firstName, lastName, group: role})
    const newUser = {
      userEmail: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      group: user.group.split(',')
    }
    return dispatch({
      type: usersTypes.ADD_USER,
      payload: newUser,
    });
  } catch (err) {
    console.log("GET USERS ERROR - ", err)
    return dispatch({ type: usersTypes.USERS_ACTION_FAILED });
  }  
};

export const disableUser = (userEmail) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const result = await api.disableUser(userEmail)
    console.log("RESULT - ",result)
  } catch (err) {
    console.log("DISABLE USER ERROR - ", err)
  }  
};

export const enableUser = (userEmail) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const result = await api.enableUser(userEmail)
    console.log("RESULT - ",result)
  } catch (err) {
    console.log("ENABLE USER ERROR - ", err)
  }  
};

export const createGroup = (group, precedence) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const result = await api.createGroup(group, precedence)
    console.log("RESULT - ",result)
  } catch (err) {
    console.log("CREATE GROUP ERROR - ", err)
  }  
};

export const deleteGroup = (group) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const result = await api.deleteGroup(group)
    console.log("RESULT - ",result)
  } catch (err) {
    console.log("DELETE GROUP ERROR - ", err)
  }  
};

export const addUserToGroup = ({ userEmail, group }) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const result = await api.addUserToGroup({ userEmail, group })
    let groups = await getAllUserGroups({userEmail})
    groups = groups.map(group => group.GroupName).join(',')
    const attributes = { groups }
    await api.updateUser({ userEmail, attributes })
    console.log("RESULT - ",result)
  } catch (err) {
    console.log("ADD USER TO GROUP ERROR - ", err)
  } 
}

export const removeUserFromGroup = ({ userEmail, group }) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    let groups = await getAllUserGroups({userEmail})
    const result = await api.removeUserFromGroup({ userEmail, group })
    groups = groups.map(group => group.GroupName)
    const idx = groups.findIndex(elem => elem === group)
    if ( idx >= 0 ) {
      groups = groups.splice(idx,1).join(',')
      const attributes = { groups }
      await api.updateUser({ userEmail, attributes })
    }
    console.log("RESULT - ",result)
  } catch (err) {
    console.log("REMOVE USER FROM GROUP ERROR - ", err)
  } 
}

export const updateUser = ({ index, userEmail, firstName, lastName, oldGroups, newGroups}) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const { addedGroups, removedGroups } = getRemovedAddedGroups({oldGroups, newGroups})
    const attributes = {
      firstName,
      lastName,
      groups: addedGroups.join(',')
    }
    const result = await api.updateUser({ userEmail, attributes })
    for (let addedGroup of addedGroups) {
      await api.addUserToGroup({ userEmail, group: addedGroup })
    }
    for (let removedGroup of removedGroups) {
      await api.removeUserFromGroup({userEmail, group: removedGroup })
    }
    console.log("RESULT - ",result)
    return dispatch({
      type: usersTypes.UPDATE_USER,
      payload: { index, data: result }
    });
  } catch (err) {
    console.log("UPDATE USER ERROR - ", err)
  } 
}


export const deleteUser = (userEmail, index) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    await api.deleteUser(userEmail)
    return dispatch({
      type: usersTypes.DELETE_USER,
      payload: index,
    });
  } catch (err) {
    console.log("DELETE USER ERROR - ", err)
  } 
};


export const getUserGroups = ({page = FIRST_PAGE, limit = PAGE_LIMIT, userEmail}) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const groups = await getAllUserGroups({userEmail})
    const totalCount = groups.length
    const pageCount =  Math.ceil(groups.length / limit)
    const result = groups.splice(limit * (page - 1), limit * (page - 1) + limit)
    const pagination = {
      page,
      pageCount,
      totalCount,
    }
    console.log("RESULT - ",result, pagination)
  } catch (err) {
    console.log("GET USER GROUPS ERROR - ", err)
  } 
};

export const getUsersByGroup = ({page = PAGE_LIMIT, limit = FIRST_PAGE, group}) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const users = await getAllUsersByGroup({group})
    const totalCount = users.length
    const pageCount =  Math.ceil(users.length / limit)
    const result = users.splice(limit * (page - 1), limit * (page - 1) + limit)
    const pagination = {
      page,
      pageCount,
      totalCount,
    }
    console.log("RESULT - ",result, pagination)
  } catch (err) {
    console.log("GET USERS BY GROUP ERROR - ", err)
  } 
};

export const getGroups = ({page = PAGE_LIMIT, limit = FIRST_PAGE }) => async (dispatch) => {
  try {
    dispatch({ type: usersTypes.USERS_START_ACTION });
    const groups = await getAllGroups({})
    const totalCount = groups.length
    const pageCount =  Math.ceil(groups.length / limit)
    const result = groups.splice(limit * (page - 1), limit * (page - 1) + limit)
    const pagination = {
      page,
      pageCount,
      totalCount,
    }
    console.log("RESULT - ",result, pagination)
  } catch (err) {
    console.log("GET GROUPS ERROR - ", err)
  } 
};
