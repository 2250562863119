import React from 'react';

import {
  Form, Row, Col, Input, Upload, Button
} from 'antd';

const FilesAddForm = ({ form }) => {
  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,100}$/,
              message: 'The title should contain only English letters and be from 2 to 100 characters long',
            }]}
          >
            <Input
              placeholder="Please enter title"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="route"
            label="Route"
            rules={[{
              required: true,
              pattern: /^[0-9a-z._/-]{2,120}$/,
              message: 'Please use only numbers, lower case latin letters or symbols . - _',
            }]}
          >
            <Input
              placeholder="Please enter route"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="file"
            label="File"
            rules={[{ required: true, message: 'Please, select file' }]}
          >
            <Upload
              maxCount={1}
              beforeUpload={() => false}
            >
              <Button>Select file</Button> 
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilesAddForm;