const getFormData = (objectData) => {
  const formData = new FormData();
    
  Object.keys(objectData).forEach((key) => {
    if (!objectData[key]) return;

    if (objectData[key] instanceof Object && !Array.isArray(objectData[key]) && objectData[key].file) {
      return formData.append(key, objectData[key].file);
    }

    if (objectData[key] instanceof Object && !Array.isArray(objectData[key])) {
      return formData.append(key, JSON.stringify(objectData[key]));
    }

    if (Array.isArray(objectData[key])) {
      return objectData[key].forEach((value) => formData.append(`${key}[]`, value));
    }

    return formData.append(key, objectData[key]);
  });
  
  return formData;
};

export default getFormData;