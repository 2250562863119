import { message } from 'antd';

import jobsTypes from './jobsTypes';
import { getAllJobs, getAllPosts } from '../../utils/getAllData';
import api from '../../services'
import { FIRST_PAGE, PAGE_LIMIT } from '../../constants/constants';

export const getJobs = ({page = FIRST_PAGE, limit = PAGE_LIMIT, search}) => async (dispatch) => {
  dispatch({ type: jobsTypes.JOBS_START_ACTION });
  try {
    let allJobs = await getAllJobs({})
    allJobs = JSON.parse(JSON.stringify(allJobs))
    const posts = await getAllPosts({})
    posts.forEach(post => {
      for (let job of allJobs) {
        if (post.jobID === job.id) {
          // eslint-disable-next-line no-mixed-operators
          !job?.countPosts && (job.countPosts = 0) || (job.countPosts += 1)
          break
        }
      }
    })
    let result = allJobs
    if (search) {
      const re = new RegExp(search.toUpperCase(), 'g')
      result = allJobs.filter(job => job.title.toUpperCase().match(re))
    }
    const pageCount =  Math.ceil(result.length / limit)
    const totalCount = result.length 
    const jobs = result.splice(limit * (page - 1), limit * (page - 1) + limit)
    return dispatch({
      type: jobsTypes.GET_LIST_JOBS,
      payload: {
        jobs: jobs,
        pagination:  {
          page,
          pageCount,
          totalCount
        }
      },
    });
  } catch (err) {
    console.log("GET JOBS ERROR - ", err)
    return dispatch({ type: jobsTypes.JOBS_ACTION_FAILED });
  }
};

export const deleteJob = (id, index) => async (dispatch) => {
  try {
    const job = await api.getJobById(id)
    await api.deleteJob(job)
    return dispatch({
      type: jobsTypes.DELETE_JOB,
      payload: index,
    });
  } catch (err) {
    message.error(err.message);
  }
};

export const createJob = ({slug, title }) => async (dispatch) => {
  try {
    const data = {
      slug,
      title
    }
    const job = await api.createJob(data)
    return dispatch({
      type: jobsTypes.ADD_JOB,
      payload: job,
    });
  } catch (err) {
    message.error(err.message)
  }
};

export const updateJob = (id, index, values) => async (dispatch) => {
  try {
    const {
      slug, 
      title
    } = values
    const job = await api.getJobById(id)
    const updatedJob = await api.updateJob({
      original: job,
      updated: {
        slug, 
        title
      }
    })
    return dispatch({
      type: jobsTypes.UPDATE_JOB,
      payload: { index, data: updatedJob }
    });
  } catch (err) {
    message.error(err.message);
  }
};
