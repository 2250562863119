import { createNewFile } from "../../../services/storage";

class uploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    const file = await this.loader.file
    const response =  await createNewFile({
      file: file, 
      contentType: file.type ,
      type: 'ARTICLE'
    })
    if (response) {
      return {
        default: response.url
      }
    }
  }
}

export default function uploadPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new uploadAdapter(loader);
  };
}