import { message } from 'antd';

import authorsTypes from './autorsTypes';
import { getAllAuthors, getAllPosts } from '../../utils/getAllData';
import api from '../../services'
import { FIRST_PAGE, PAGE_LIMIT } from '../../constants/constants';

export const getAuthors = ({page = FIRST_PAGE, limit = PAGE_LIMIT, search}) => async (dispatch) => {
  dispatch({ type: authorsTypes.AUTHORS_START_ACTION });
  try {
    let allAuthors = await getAllAuthors({})
    allAuthors = JSON.parse(JSON.stringify(allAuthors))
    const posts = await getAllPosts({})
    posts.forEach(post => {
      for (let author of allAuthors) {
        if (post.authorID === author.id) {
          // eslint-disable-next-line no-mixed-operators
          !author?.countPosts && (author.countPosts = 0) || (author.countPosts += 1)
          break
        }
      }
    })
    let result = allAuthors
    if (search) {
      const re = new RegExp(search.toUpperCase(), 'g')
      result = allAuthors.filter(author => author.firstName.toUpperCase().match(re) ||  author.lastName.toUpperCase().match(re))
    }
    const pageCount =  Math.ceil(result.length / limit)
    const totalCount = result.length 
    const authors = result.splice(limit * (page - 1), limit * (page - 1) + limit)
    return dispatch({
      type: authorsTypes.GET_LIST_AUTHORS,
      payload: {
        authors: authors,
        pagination:  {
          page,
          pageCount,
          totalCount
        }
      },
    });
  } catch (err) {
    console.log("GET AUTHORS ERROR - ", err)
    return dispatch({ type: authorsTypes.AUTHORS_ACTION_FAILED });
  }
};

export const deleteAuthor = (id, index) => async (dispatch) => {
  try {
    const author = await api.getAuthorById(id)
    await api.deleteAuthor(author)
    return dispatch({
      type: authorsTypes.DELETE_AUTHOR,
      payload: index,
    });
  } catch (err) {
    message.error(err.message);
  }
};

export const createAuthor = ({firstName, lastName, jobTitle, avatar, linkedinUrl, bio }) => async (dispatch) => {
  try {
    const data = {
      firstName, 
      lastName, 
      jobTitle, 
      avatar, 
      linkedinUrl, 
      bio
    }
    const author = await api.createAuthor(data)
    return dispatch({
      type: authorsTypes.ADD_AUTHOR,
      payload: author,
    });
  } catch (err) {
    message.error(err.message)
  }
};

export const updateAuthor = (id, index, values) => async (dispatch) => {
  try {
    const {
      firstName, 
      lastName, 
      jobTitle, 
      avatar, 
      linkedinUrl, 
      bio
    } = values
    const author = await api.getAuthorById(id)
    const updatedAuthor = await api.updateAuthor({
      original: author,
      updated: {
        firstName, 
        lastName, 
        jobTitle, 
        avatar, 
        linkedinUrl, 
        bio
      }
    })
    return dispatch({
      type: authorsTypes.UPDATE_AUTHOR,
      payload: { index, data: updatedAuthor }
    });
  } catch (err) {
    message.error(err.message);
  }
};
