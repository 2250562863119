export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const ROLES = [
  { key: 'Superadmin', title: 'Super Admin' },
  { key: 'Admin', title: 'Admin' },
]
export const CATEGORIES = [
  { key: 'design', title: 'Design' },
  { key: 'development', title: 'Development' },
  { key: 'marketing', title: 'Marketing' },
  { key: 'motiond-design', title: 'Motion Design' },
  { key: 'project-management', title: 'Project management' },
  { key: 'quality-assurance', title: 'Quality assurance' },
]
export const ARTICLE_STATUS = [
  { value: 'PUBLISHED', text: 'Published' },
  { value: 'DRAFT', text: 'Draft' },
  { value: 'HIDE', text: 'Hide' },
]
export const PROGRAMMING_JOBS = [
  { key: 'marketing_manager', title: 'Marketing Manager' },
  { key: 'business_analyst', title: 'Business Analyst' },
  {
    key: 'head_of_business_development',
    title: 'Head of Business Development',
  },
  { key: 'chief_information_officer', title: 'Chief Information Officer' },
  { key: 'android_developer', title: 'Android Developer' },
  { key: 'qa_engineer', title: 'QA Engineer' },
  { key: 'php_developer', title: 'PHP Developer' },
  {
    key: 'business_development_manager',
    title: 'Business Development Manager',
  },
  { key: 'ios_developer', title: 'iOS Developer' },
  { key: 'head_of_design', title: 'Head of Design' },
  { key: 'javascript_developer', title: 'JavaScript Developer' },
  { key: 'chief_executive_officer', title: 'Chief Executive Officer' },
  { key: 'chief_operating_officer', title: 'Chief Operating Officer' },
  { key: 'head_of_marketing', title: 'Head of Marketing' },
]

export const FIRST_PAGE = 1
export const PAGE_LIMIT = 20
export const ALL_DATA_FIRST_PAGE = 1
export const ALL_DATA_LIMIT = 60
export const MEDIA_TYPE = 'ARTICLE'
export const BUCKET_REGION = 'eu-west-2'
export const BUCKET_S3 = 'drafta-blog-storage'
export const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID
export const ACCESS_SECRET_KEY = process.env.REACT_APP_ACCESS_SECRET_KEY
