import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ArticlesPage from './pages/ArticlesPage'
// import SeoPage from './pages/SeoPage';
// import RobotsPage from './pages/RobotsPage';
// import TagsPage from './pages/TagsPage';
// import IndustriesPage from './pages/IndustriesPage';
// import CategoriesPage from './pages/CategoriesPage';
// import ServicePage from './pages/ServicesPage';
import UsersPage from './pages/UsersPage';
import JobsPage from './pages/JobsPage';
import FilesPage from './pages/FilesPage';
// import WorksPage from './pages/WorksPage';
import AuthorsPage from './pages/AuthorsPage';
// import MediaPage from './pages/MediaPage';
import NotFoundPage from './pages/NotFoundPage';

const Routers = ({ isAuth, user }) => {
  const guestRoutes = [{ component: LoginPage }];

  const adminRoutes = [
    { path: '/', component: HomePage },
    { path: '/articles', component: ArticlesPage },
    // { path: '/seo', component: SeoPage },
    // { path: '/robots', component: RobotsPage },
    { path: '/files', component: FilesPage },
    // { path: '/works', component: WorksPage },
    { path: '/authors', component: AuthorsPage },
    // { path: '/media', component: MediaPage },
    // { path: '/settings/tags', component: TagsPage },
    // { path: '/settings/industries', component: IndustriesPage },
    // { path: '/settings/services', component: ServicePage },
    // { path: '/settings/categories', component: CategoriesPage },
    { component: NotFoundPage },
  ];

  const superAdminRoutes = [
    { path: '/settings/users', component: UsersPage },
    { path: '/settings/jobs', component: JobsPage },
    ...adminRoutes,
  ];

  const renderRouters = () => {
    const authRoutes = isAuth && (user.get('role') === 'admin') ? adminRoutes : superAdminRoutes;

    const routes = isAuth ? authRoutes : guestRoutes;

    return routes.map((route, idx) => (
      <Route exact path={route.path} component={route.component} key={route.path || idx} />
    ));
  };

  return (
    <Router>
      <Switch>
        {renderRouters()}
      </Switch>
    </Router>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.get('isAuth'),
  user: auth.get('user')
});

export default connect(mapStateToProps)(Routers);
