import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { FIRST_PAGE, PAGE_LIMIT } from '../constants/constants';
import { Author, Post, Media, Category } from '../models/index.js';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async getAuthors({page = FIRST_PAGE, limit = PAGE_LIMIT}){
    return await DataStore.query(Author, Predicates.ALL, {
      sort: s => s.updatedAt(SortDirection.DESCENDING),
      page: page - 1,
      limit
    })
  },

  async getAuthorById(id){
    return await DataStore.query(Author, id)
  },

  async updateAuthor({original, updated}){
    const {
      firstName, 
      lastName,
      jobTitle,
      avatar,
      linkedinUrl,
      bio
    } = updated
    return await DataStore.save(
      Author.copyOf(original, updated => {
        updated.firstName = firstName
        updated.lastName = lastName
        updated.jobTitle = jobTitle
        updated.avatar = avatar
        updated.linkedinUrl = linkedinUrl
        updated.bio = bio
      })
    )
  },

  async createAuthor({firstName, lastName, jobTitle, avatar, linkedinUrl, bio}){
    return await DataStore.save(
      new Author({
        firstName,
        lastName,
        jobTitle,
        avatar,
        linkedinUrl,
        bio
      })
    )
  },

  async deleteAuthor(author){
    return await DataStore.delete(author)
  },

  async getPosts({page, limit}){
    return await DataStore.query(Post, Predicates.ALL, {
      sort: s => s.updatedAt(SortDirection.DESCENDING),
      page: page - 1,
      limit
    })
  },

  async getPostsByCategory({category, page = FIRST_PAGE, limit = PAGE_LIMIT}){
    return await DataStore.query(Post, (post) => post.category('eq', category), {
      sort: s => s.updatedAt(SortDirection.DESCENDING),
      page: page - 1,
      limit
    })
  },

  async getPostByUrl(url){
    return await DataStore.query(Post, (post) => post.url('eq', url))
  },

  async getPostById(id){
    return await DataStore.query(Post, id)
  },

  async createPost({
    title,
    url,
    content,
    description,
    readTime,
    preview,
    category,
    authorID,
    status
  }){
    return await DataStore.save(
      new Post({
        title,
        url,
        content,
        description,
        readTime,
        preview,
        category,
        authorID,
        status,
        rating: {
          sum: 0,
          totalVotes: 0
        }
      })
    )
  },

  async updatePost({original, updated}){
    const { 
      title,
      url,
      content,
      description,
      readTime,
      preview,
      category,
      authorID,
      status
    } = updated
    return await DataStore.save(
      Post.copyOf(original, updated => {
        updated.title = title
        updated.url = url
        updated.content = content
        updated.description = description
        updated.readTime = readTime
        updated.preview = preview
        updated.category = category
        updated.authorID = authorID
        updated.status = status
      })
    )
  },

  async deletePost(post){
    return await DataStore.delete(post)
  },

  async createMedia({ fileName, url, type }){
    return await DataStore.save(
      new Media({
        fileName,
        url,
        type
      })
    )
  },

  async getMediaByType({type, page, limit}){
    return await DataStore.query(Media, (media) => media.type('eq', type), {
      page,
      limit
    })
  },

  async deleteMedia(media){
    return await DataStore.delete(media)
  },

  async getMediaById(id){
    return await DataStore.query(Media, id)
  },

  async getMediaByFileName(fileName){
    return await DataStore.query(Media, (media) => media.fileName('eq', fileName))
  },

  async createCategories(categories){
    return await DataStore.save(
      new Category({
        categories
      })
    )
  },

  async getCategories(){
    return await DataStore.query(Category)
  },

  async updateCategories({ original, updated }){
    const { 
      id,
      categories
    } = updated
    return await DataStore.save(
      Post.copyOf(original, updated => {
        updated.id = id
        updated.categories = categories
      })
    )
  },

  // Jobs
  
  async getJobs({page = FIRST_PAGE, limit = PAGE_LIMIT}){
    // return await DataStore.query(Job, Predicates.ALL, {
    //   sort: s => s.updatedAt(SortDirection.DESCENDING),
    //   page: page - 1,
    //   limit
    // })
  },

  async getJobById(id){
    // return await DataStore.query(Job, id)
  },

  async updateJob({original, updated}){
    // const {
    //   slug,
    //   title
    // } = updated
    // return await DataStore.save(
    //   Job.copyOf(original, updated => {
    //     updated.slug = slug
    //     updated.title = title
    //   })
    // )
  },

  async createJob({slug, title}){
    // return await DataStore.save(
    //   new Job({
    //     slug,
    //     title
    //   })
    // )
  },

  async deleteJob(job){
    return await DataStore.delete(job)
  }

}
