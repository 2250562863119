import React from 'react';

import DefaultLayout from '../../components/layouts/DefaultLayout';

const HomePage = () => {
  return (
    <DefaultLayout>
      HomePage
    </DefaultLayout>
  );
};

export default HomePage;
