import React, { useEffect } from 'react';

import { Form, Row, Col, Input, Select } from 'antd';

const UserAddForm = ({ form, roles, editingItem }) => {
  useEffect(() => {
    form.resetFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);
  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="userEmail"
            label="Email"
            initialValue={editingItem?.userEmail}
            rules={[{ 
              required: true,
              // pattern: /^[\w.]+@[a-z]$/,
              message: 'Email must end ".co". Example: name@companydomain.co' 
            }]}
          >
            <Input
              placeholder="Please enter email" 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="firstName"
            label="First name"
            initialValue={editingItem?.firstName}
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20 characters long',
            }]}
          >
            <Input
              placeholder="Please enter first name"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="lastName"
            label="Last name"
            initialValue={editingItem?.lastName}
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20 characters long',
            }]}
          >
            <Input
              placeholder="Please enter last name"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="role"
            label="Role"
            initialValue={editingItem?.role}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              placeholder="Please select role"
              allowClear
            >
              {roles}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserAddForm;