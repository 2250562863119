import React, { useEffect, useState } from 'react';
import {useRef} from 'react';

import { Form, Row, Col, Input, Button, Select, Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { createNewFile } from '../../../services/storage';

const { TextArea } = Input;

const AuthorAddOrEditForm = ({ form, jobs, editingItem }) => {
  const inputRef = useRef(null);
  const [ loading, setLoading ] = useState(false);
  const [ fileURL, setFileURL ] = useState('');

  useEffect(() => {
    form.resetFields();
    setFileURL(editingItem?.avatar)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  
  const handleFileChange = async (e) => {
    setLoading(true)
    const response =  await createNewFile({
      file: e.currentTarget.files[0], 
      contentType: e.currentTarget.files[0].type ,
      type: 'AVATAR'
    })
    if (response) {
      setFileURL(response?.url)
      form.setFieldsValue({avatar: response?.url})
      setLoading(false)
    }
  }  

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="firstName"
            label="First name"
            initialValue={editingItem?.firstName}
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20 characters long',
            }]}
          >
            <Input
              placeholder="Please enter first name"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="lastName"
            label="Last name"
            initialValue={editingItem?.lastName}
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20 characters long',
            }]}
          >
            <Input
              placeholder="Please enter last name"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="jobTitle"
            label="Job"
            initialValue={editingItem ? editingItem.jobTitle : 'marketing_manager'}
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20 characters long',
            }]}
          >
            <Select>
              {jobs}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="linkedinUrl"
            label="Linkedin URL"
            initialValue={editingItem?.linkedinUrl}
          >
            <Input
              placeholder="Please enter linkedin"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="bio"
            label="Bio"
            initialValue={editingItem?.bio}
            rules={[{ max: 250 }]}
          >
            <TextArea 
              autoSize={{ minRows: 5, maxRows: 5 }}
              placeholder="Please enter description" 
              showCount 
              maxLength={250}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Avatar"
            name="avatar"
            initialValue={editingItem?.avatar}            
            rules={[
              {
                required: true, 
                message: 'Please choose an image!' 
              }
            ]}          
          >
            {fileURL && <Avatar size={64} src={fileURL} />}
            <input
              style={{display: 'none'}}
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
            <Col style={{paddingTop: '10px'}}>
              <Button 
                icon={<UploadOutlined />} 
                loading={loading}
                disabled={loading}
                onClick={handleClick}
              >
                { fileURL ? 'Select avatar' : 'Select new avatar' }
              </Button>
            </Col>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AuthorAddOrEditForm;
