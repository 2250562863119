import graphql_api from './graphql'
import superAdmin_api from './superAdmin'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async getAuthors({page, limit}) {
    return graphql_api.getAuthors({page, limit})
  },

  async getAuthorById(id) {
    return graphql_api.getAuthorById(id)
  },

  async createAuthor(author) {
    return graphql_api.createAuthor(author)
  },

  async updateAuthor({original, updated}) {
    return graphql_api.updateAuthor({ original, updated })
  },

  async deleteAuthor(author) {
    return graphql_api.deleteAuthor(author)
  },

  async getPosts({page, limit}){
    return graphql_api.getPosts({page, limit})
  },

  async getPostsByCategory({category, page, limit}) {
    return graphql_api.getPostsByCategory({category, page, limit})
  },

  async getPostById(id){
    return graphql_api.getPostById(id)
  },

  async createPost(post){
    return graphql_api.createPost(post)
  },

  async updatePost({original, updated}){
    return graphql_api.updatePost({original, updated})
  },
  
  async deletePost(post){
    return graphql_api.deletePost(post)
  },

  async createUser({userEmail, firstName, lastName, group}){
    return superAdmin_api.createUser({userEmail, firstName, lastName, group})
  },

  async getAllUsers({token, limit}){
    return superAdmin_api.getAllUsers({token, limit})
  },

  async disableUser(userEmail){
    return superAdmin_api.disableUser(userEmail)
  },

  async enableUser(userEmail){
    return superAdmin_api.enableUser(userEmail)
  },

  async createGroup(group, precedence){
    return superAdmin_api.createGroup(group, precedence)
  },

  async deleteGroup(group){
    return superAdmin_api.deleteGroup(group)
  },

  async getUserGroups({userEmail, limit, token}){
    return superAdmin_api.getUserGroups({userEmail, limit, token})
  },

  async getAllUsersByGroup({group, limit, token}){
    return superAdmin_api.getAllUsersByGroup({group, limit, token})
  },

  async addUserToGroup({userEmail, group}){
    return superAdmin_api.addUserToGroup({userEmail, group})
  },

  async removeUserFromGroup({userEmail, group}){
    return superAdmin_api.removeUserFromGroup({userEmail, group})
  },

  async getAllGroups({limit, token}){
    return superAdmin_api.getAllGroups({limit, token})
  },

  async updateUser({userEmail, attributes}){
    return superAdmin_api.updateUser({userEmail, attributes})
  },

  async deleteUser(userEmail){
    return superAdmin_api.deleteUser(userEmail)
  },

  async createMedia(media){
    return graphql_api.createMedia(media)
  },
  
  async deleteMedia(media){
    return graphql_api.deleteMedia(media)
  },
  
  async getMediaById(id){
    return graphql_api.getMediaById(id)
  },
  
  async getMediaByFileName(fileName){
    return graphql_api.getMediaByFileName(fileName)
  },
  
  async getMediaByType({type, page, limit}){
    return graphql_api.getMediaByType({type, page, limit})
  },

  async createCategories(categories){
    return graphql_api.createCategories(categories)
  },

  async getCategories(){
    return graphql_api.getCategories()
  },

  async updateCategories({original, updated}){
    return graphql_api.updateCategories({original, updated})
  }
  
}
