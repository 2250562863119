import cookie from 'js-cookie';

import initStoreAndHistory from '../initStoreAndHistory';
import authTypes from '../reducers/auth/authTypes';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/constants';

const storeAndHistory = Symbol('storeAndHistory');
const isPending = Symbol('isPending');
const promise = Symbol('promise');
const currPromise = Symbol('currPromise');

class AuthProvider {
  [storeAndHistory] = initStoreAndHistory();

  [isPending] = false;

  [currPromise] = null;

  [promise] = (axiosInstance) => {
    return new Promise((resolve) => {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);

      if (!refreshToken) {
        this.removeSession();
        return false;
      }

      return axiosInstance({
        method: 'patch',
        url: '/auth',
        data: {
          refreshToken
        },
        withoutAccess: true,
      })
        .then(({ data, status }) => {
          if (status === 200) {
            this.setTokens(data.accessToken, data.refreshToken, data.expires);
            this[isPending] = false;
            return resolve(true);
          }
          this.removeSession();

          this[isPending] = false;
          return resolve(false);
        })
        .catch(() => {
          this.removeSession();
          this[isPending] = false;
          return resolve(false);
        });
    });
  };

  refresh = async (axiosInstance) => {
    if (this[isPending]) return this[currPromise];

    this[currPromise] = this[promise](axiosInstance).then((result) => result);
    this[isPending] = true;
    return this[currPromise];
  };

  getAccessToken = () => {
    return cookie.get(ACCESS_TOKEN);
  };

  getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
  };

  removeSession = () => {
    this[storeAndHistory].store.dispatch({ type: authTypes.AUTH__FAILED });
    cookie.remove(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  };

  setTokens = (accessToken, refreshToken, accessExpires) => {
    // время жизни куки на 5% меньше времени жизни accessToken
    // для избежания проблем при запросе в момент истечения куки

    const realExpires = (accessExpires - (accessExpires * 0.05)) / (24 * 60 * 60);
    cookie.set(ACCESS_TOKEN, accessToken, { expires: realExpires });
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  };
}

const authProvider = new AuthProvider();

export { AuthProvider, authProvider };
