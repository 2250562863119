import { v4 as uuidv4 } from 'uuid';
import { BUCKET_REGION, BUCKET_S3 } from '../constants/constants';
import { deleteFromStorage, putInStorage } from '../utils/storage';
import api from '../services'


export const createNewFile = async ({file, contentType, type}) => {
  if (!file || !contentType || !type )
    throw new Error('Not enough info')
  try {
    let fileName
    while ( true ) {
      try{
        fileName = uuidv4()
        let file = await api.getMediaByFileName(fileName);
        if (!file.length) break
      }catch(err){
        console.log(err)
        break
      }
    }  

    const folder = type.toLowerCase()
    await api.createMedia({
      fileName,
      url: `https://${BUCKET_S3}.s3.${BUCKET_REGION}.amazonaws.com/${folder}/${fileName}`,
      type
    })
    const response = await putInStorage(`${folder}/${fileName}`, file, contentType, folder)
    return {
      url: response?.Location
    }
  } catch (err) {
    throw new Error(err.message)
  }
};

export const deleteFile = async (fileName) => {
  try {
    const media = await api.getMediaByFileName(fileName)
    if (media.length) {
      await api.deleteMedia(media[0])
      const folder = media[0].url.split('/').at(-2)
      await deleteFromStorage(`${folder}/${fileName}`)
    }
  } catch(err){
    throw new Error(err.message)
  }
}
