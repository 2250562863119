import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Layout, Menu, Divider } from 'antd';
import { 
  ReadOutlined, 
  TeamOutlined, 
  // FileOutlined, 
  // PictureOutlined, 
  // BlockOutlined,
  // ControlOutlined, 
  // FontSizeOutlined, 
  LogoutOutlined, 
  SettingOutlined,
  // MailOutlined,
  // AppstoreOutlined
} from '@ant-design/icons';

import { signOutAuth } from '../../../reducers/auth/actions';

// const { SubMenu } = Menu;

const SideMenu = ({ className, collapsed, broken, onCollapse, onBreakpoint }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const role = useSelector((state) => state.auth.get('user').get('role'));
  const logOut = () => {
    dispatch(signOutAuth());
  };
  const items = [
    {
      label: (
        <Link to="/articles">Articles</Link>
      ),
      key: '/articles',
      icon: <ReadOutlined />,
    },
    {
      label: (
        <Link to="/authors">Authors</Link>
      ),
      key: '/authors',
      icon: <TeamOutlined />,
    },
    // {
    //   label: (
    //     <Link to="/media">Media</Link>
    //   ),
    //   key: '/media',
    //   icon: <PictureOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/works">Works</Link>
    //   ),
    //   key: '/works',
    //   icon: <BlockOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/files">Files</Link>
    //   ),
    //   key: '/files',
    //   icon: <FileOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/seo">SEO</Link>
    //   ),
    //   key: '/seo',
    //   icon: <ControlOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/robots">Robots</Link>
    //   ),
    //   key: '/robots',
    //   icon: <FontSizeOutlined />,
    // },
    // {
    //   label: 'Settings',
    //   key: 'SubMenu',
    //   icon: <SettingOutlined />,
    //   children: [
    //     // {
    //     //   label: (
    //     //     <Link to="/settings/categories">Categories</Link>
    //     //   ),
    //     //   key: '/settings/categories',
    //     // },
    //     // {
    //     //   label: (
    //     //     <Link to="/settings/services">Services</Link>
    //     //   ),
    //     //   key: '/settings/services',
    //     // },
    //     // {
    //     //   label: (
    //     //     <Link to="/settings/industries">Industries</Link>
    //     //   ),
    //     //   key: '/settings/industries',
    //     // },
    //     // {
    //     //   label: (
    //     //     <Link to="/settings/users">Users</Link>
    //     //   ),
    //     //   key: '/settings/users',
    //     // },
    //     // {
    //     //   label: (
    //     //     <Link to="/settings/change_password">Change password</Link>
    //     //   ),
    //     //   key: '/settings/change_password',
    //     // },
    //   ],
    // },
    {
      label: (<Divider />)
    },
    {
      label: (
        <div onClick={logOut}>Logout</div>
      ),
      icon: <LogoutOutlined />,
      key: 'logout',
    },
  ];
  const itemsSuperAdmin = [
    {
      label: (
        <Link to="/articles">Articles</Link>
      ),
      key: '/articles',
      icon: <ReadOutlined />,
    },
    {
      label: (
        <Link to="/authors">Authors</Link>
      ),
      key: '/authors',
      icon: <TeamOutlined />,
    },
    // {
    //   label: (
    //     <Link to="/media">Media</Link>
    //   ),
    //   key: '/media',
    //   icon: <PictureOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/works">Works</Link>
    //   ),
    //   key: '/works',
    //   icon: <BlockOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/files">Files</Link>
    //   ),
    //   key: '/files',
    //   icon: <FileOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/seo">SEO</Link>
    //   ),
    //   key: '/seo',
    //   icon: <ControlOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/robots">Robots</Link>
    //   ),
    //   key: '/robots',
    //   icon: <FontSizeOutlined />,
    // },
    {
      label: 'Settings',
      key: 'SubMenu',
      icon: <SettingOutlined />,
      children: [
        // {
        //   label: (
        //     <Link to="/settings/categories">Categories</Link>
        //   ),
        //   key: '/settings/categories',
        // },
        // {
        //   label: (
        //     <Link to="/settings/services">Services</Link>
        //   ),
        //   key: '/settings/services',
        // },
        // {
        //   label: (
        //     <Link to="/settings/industries">Industries</Link>
        //   ),
        //   key: '/settings/industries',
        // },
        {
          label: (
            <Link to="/settings/users">Users</Link>
          ),
          key: '/settings/users',
        },
        {
          label: (
            <Link to="/settings/jobs">Jobs</Link>
          ),
          key: '/settings/jobs',
        },
        // {
        //   label: (
        //     <Link to="/settings/change_password">Change password</Link>
        //   ),
        //   key: '/settings/change_password',
        // },
      ],
    },
    {
      label: (<Divider />)
    },
    {
      label: (
        <div onClick={logOut}>Logout</div>
      ),
      icon: <LogoutOutlined />,
      key: 'logout',
    },
  ];
  return (
    <Layout.Sider
      className={className}
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      style={{
        display: broken && collapsed ? 'none' : 'block',
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 64,
        bottom: 0,
      }}
      onBreakpoint={onBreakpoint}
    >
      <Menu 
        mode="inline" 
        className="default-layout__sider-menu"
        defaultSelectedKeys={['/']}
        selectedKeys={[location.pathname]}
        items={role === 'Superadmin' ? itemsSuperAdmin : items }
      >
      </Menu>
    </Layout.Sider>
  );
};

export default SideMenu;