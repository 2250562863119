import React from 'react';

import initStoreAndHistory from './initStoreAndHistory';
import { initialAuth } from './reducers/auth/actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default (App) => {
  const storeAndHistory = initStoreAndHistory();

  // глобальные экшены
  // например проверка авторизации или получение данных необходимых по всему проекту
  storeAndHistory.store.dispatch(initialAuth());

  return () => {
    return (
      <App store={storeAndHistory.store} />
    );
  };
};
