import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Popconfirm, Select, Space } from 'antd';

import DefaultLayout from '../../components/layouts/DefaultLayout';
import DefaultTable from '../../components/common/tables/DefaultTable';
import TableHeader from '../../components/common/tables/TableHeader';
import UserAddForm from '../../components/user/UserAddForm';
import DefaultPagination from '../../components/common/Pagination';

import { ROLES } from '../../constants/constants';
import pageChanger from '../../utils/pageChanger';
import { getUsers, updateUser, deleteUser, createUser } from '../../reducers/users/actions';

const UsersPage = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.get('users'));
  const loading = useSelector((state) => state.users.get('loading'));
  const totalCount = useSelector((state) => state.users.getIn(['pagination', 'totalCount']));
  const pageCount = useSelector((state) => state.users.getIn(['pagination', 'pageCount']));
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [editingObject, setEditingObject] = useState(null);

  useEffect(() => {
    dispatch(getUsers(currentPage, limit));
  }, [dispatch, currentPage, limit]);

  useEffect(() => {
    pageChanger(users.size, getUsers, currentPage, limit, pageCount, setCurrentPage, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.size]);

  const onShowEditingWindow = (index, user) => {
    setEditingObject({ ...user, index });
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const onUpdateUser = (id, index, values) => {
    dispatch(updateUser(id, index, values));
  };

  const onDeleteUser = (email, index) => {
    dispatch(deleteUser(email, index));
  };

  const onAddUser = (user) => {
    console.log('onAddUser - ', user);
    dispatch(createUser(user));
  };

  const rolesOptions = ROLES.map((role) => {
    return <Select.Option value={role.key}>{role.title}</Select.Option>;
  });

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render: (_, item) => {
        return <>{item.firstName}&nbsp;{item.lastName}</>
      },
    },
    {
      title: 'Email',
      dataIndex: 'userEmail',
    },
    {
      title: 'Role',
      render: (_, item) => {
        return <>{item?.groups}</>
      },
    },
  ];

  const actionColumn = {
    title: 'Actions',
    width: '10%',
    render: (_, item, index) => {
      return (
        <Space size="middle">
          <Button type="link" onClick={() => onShowEditingWindow(index, item)}>Edit</Button>
          <Popconfirm title="Delete this item?" onConfirm={() => onDeleteUser(item.userEmail, index)}>
          <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      );
    },
  };

  return (
    <DefaultLayout>
      <TableHeader 
        title="Users" 
        onAdd={onAddUser}
        onUpdate={onUpdateUser}
        editingItem={editingObject}
        setEditingItem={setEditingObject}
      >
        <UserAddForm
          roles={rolesOptions}
          editingItem={editingObject} 
        />
      </TableHeader>
      <DefaultTable
        data={users}
        loading={loading}
        columns={columns}
        actionColumn={actionColumn}
      />
      <DefaultPagination
        totalCount={totalCount} 
        currentPage={currentPage} 
        onChange={onPageChange} 
      />
    </DefaultLayout>
  );
};

export default UsersPage;