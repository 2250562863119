import { message } from 'antd';

import articlesTypes from './articlesTypes';
import api from '../../services'
import { getAllAuthors, getAllPosts } from '../../utils/getAllData';
import { FIRST_PAGE, PAGE_LIMIT } from '../../constants/constants';


export const getArticles = ({page = FIRST_PAGE, limit = PAGE_LIMIT, category = null, search}) => async (dispatch) => {
  dispatch({ type: articlesTypes.ARTICLES_START_ACTION });
  try {
    let authors = await getAllAuthors({})
    const allPosts  = await getAllPosts({category})
    let result = allPosts
    if (search) {
      const re = new RegExp(search.toUpperCase(), 'g')
      result = allPosts.filter(post => post.title.toUpperCase().match(re) ||  post.description.toUpperCase().match(re))
    }
    const pageCount =  Math.ceil(result.length / limit)
    const totalCount = result.length 
    let posts = result.splice(limit * (page - 1), limit * (page - 1) + limit)
    posts = posts.map(post => {
      for (let author of authors) {
        if (author.id === post.authorID) {
          post = JSON.parse(JSON.stringify(post))
          post.Author = JSON.parse(JSON.stringify(author))
          break
        }
      }
      return post
    })
    console.log(posts)
    return dispatch({
      type: articlesTypes.GET_LIST_ARTICLES,
      payload: {
        articles: posts,
        pagination: {
          page,
          pageCount,
          totalCount
        }
      },
    });
  } catch (err) {
    message.error(err.message);
    return dispatch({ type: articlesTypes.ARTICLES_ACTION_FAILED });
  }
};

export const deleteArticle = (id, index) => async (dispatch) => {
  try {
    const post = await api.getPostById(id)
    await api.deletePost(post)
    return dispatch({
      type: articlesTypes.DELETE_ARTICLE,
      payload: index,
    });
  } catch (err) {
    message.error(err.message);
  }
};

export const createArticle = ({ 
  title,
  url,
  content,
  description,
  readTime,
  preview,
  category,
  authorID,
  status
 }) => async (dispatch) => {
  try {
    const data = {
      title,
      url,
      content,
      description,
      readTime,
      preview,
      category,
      authorID,
      status
    }
    const post = await api.createPost(data)
    return dispatch({
      type: articlesTypes.ADD_ARTICLE,
      payload: post,
    });
  } catch (err) {
    message.error(err);
    return dispatch({ type: articlesTypes.ARTICLES_ACTION_FAILED });
  }
};

export const updateArticle = (id, index, values) => async (dispatch) => {
  try {
    const {
      title,
      url,
      content,
      description,
      readTime,
      preview,
      category,
      authorID,
      status
    } = values
    const post = await api.getPostById(id)
    const updatedPost = await api.updatePost({
      original: post,
      updated: {
        title,
        url,
        content,
        description,
        readTime,
        preview,
        category,
        authorID,
        status
      }
    })
    return dispatch({
      type: articlesTypes.UPDATE_ARTICLE,
      payload: { index, data: updatedPost }
    });
  } catch (err) {
    message.error(err);
    return dispatch({ type: articlesTypes.ARTICLES_ACTION_FAILED });
  }
};
