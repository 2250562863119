import AWS from 'aws-sdk'
import { ACCESS_KEY_ID, BUCKET_REGION, BUCKET_S3, ACCESS_SECRET_KEY } from '../constants/constants';
const s3 = new AWS.S3({
  region: BUCKET_REGION,
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: ACCESS_SECRET_KEY,
})


export const putInStorage = async (fileName, file, contentType) => {
  try {
    const uploadedImage = await s3.upload({
      Bucket: BUCKET_S3,
      Key: fileName,
      Body: file,
    }).promise()
    return uploadedImage
  }catch(err){
    throw new Error(err.message)
  }
}

export const deleteFromStorage = async (fileName) => {
  try { 
    const params = {  
      Bucket: BUCKET_S3, 
      Key: fileName 
    }
    const result = await s3.deleteObject(params).promise()
    return result
  } catch (err) {
    throw new Error(err.message)
  }
}