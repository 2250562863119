import React from 'react';

import { Form } from 'antd';

const EditableCell = ({
  editable,
  dataIndex,
  component,
  rules,
  children,
}) => {
  return (
    <td>
      {editable ? (
        <Form.Item
          name={dataIndex}
          rules={rules}
        >
          {component}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;