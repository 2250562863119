import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './index.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const Spinner = () => {
  return (
    <div className="spinner">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Spinner;
