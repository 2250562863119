// import { message } from 'antd';
import { Auth } from 'aws-amplify';
import passwordsTypes from './passwordsTypes';
// import axios from '../../utils/axios';

const sendEmailWithRecoveryLink = (email) => async (dispatch) => {
  dispatch({ type: passwordsTypes.PASSWORD_ACTION_START });
  console.log(email);
  Auth.forgotPassword(email.email)
  .then(data => console.log(data))
  .catch(err => console.log(err));
  // const { status, data } = await axios({
  //   method: 'POST',
  //   url: '/users/forgotPassword',
  //   data: email
  // });

  // if (status === 200) {
  //   return dispatch({ type: passwordsTypes.SEND_EMAIL_WITH_RECOVERY_LINK });
  // }

  // message.error(data.message);
  return dispatch({ type: passwordsTypes.PASSWORDS_ACTION_FAILED });
};

export default sendEmailWithRecoveryLink;