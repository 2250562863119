import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { PageHeader, Button, Drawer, Space, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Search from './Search';

const TableHeader = ({ title, onAdd, search, editingItem, setEditingItem, onUpdate, drawerWidth, children }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editingItem) setOpen(true);
  }, [editingItem]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    if (editingItem) setEditingItem(null);
    setOpen(false);
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    if (editingItem) {
      onUpdate(editingItem.id, editingItem.index, values);
    } else {
      onAdd(values);
    }
    form.resetFields();
    onClose();
  };

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return <React.Fragment key={child.id}>{React.cloneElement(child, {form})}</React.Fragment>
    }
    return <React.Fragment key={child.id}>{child}</React.Fragment>
  });

  return (
    <PageHeader
      ghost={false}
      title={title}
      extra={[
        <Space key="1">
          {search.length > 0 && <Search search={search} />}
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showDrawer}
          >
            Add
          </Button>
        </Space>
      ]}
    >
      <Drawer
        title={`${editingItem ? 'Editing' : 'Adding new'} item to ${title}`}
        width={drawerWidth || 500}
        onClose={onClose}
        open={open}
        footer={
          (
            <div style={{ textAlign: 'right' }}>
              <Space size="middle">
                <Button onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={onSubmit} type="primary">
                  {editingItem ? 'Update' : 'Create'}
                </Button>
              </Space>
            </div>
          )
        }
      >
        {childrenWithProps}
      </Drawer>
    </PageHeader>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  search: PropTypes.arrayOf(PropTypes.func),
  children: PropTypes.object.isRequired,
  editingItem: PropTypes.object,
  onUpdate: PropTypes.func,
  setEditingItem: PropTypes.func,
  drawerWidth: PropTypes.number,
};

TableHeader.defaultProps = {
  search: [],
  editingItem: null,
  onUpdate: null,
  setEditingItem: null,
  drawerWidth: null
};

export default TableHeader;