import axios from 'axios';

import { authProvider } from '../providers/authProvider';

const instance = axios.create({
  baseURL: 'https://6ks6flyyj8.execute-api.eu-west-2.amazonaws.com/staging',
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'en', // todo сделать возможность менять язык когда будет локаль
  },
  validateStatus: (status) => {
    return status >= 200 && status < 500; // возможно понадобится более жесткое условие
  }
});

instance.interceptors.response.use((res) => {
  if (res.status === 401) {
    // удаляем все что связано с сессией юзера
    // может возникнуть в случает когда AccessToken - не истек по времени,
    // но стал по каким-то причинам не валидным
    authProvider.removeSession();
    return res;
  }
  return res;
});

const axiosInstance = async ({
  method = 'get',
  data = {},
  url,
  headers = {},
  withoutAccess = false,
}) => {
  const axiosHeaders = { ...headers };

  let access = authProvider.getAccessToken();
  const refresh = authProvider.getRefreshToken();

  if (!access && !withoutAccess && refresh) {
    const refreshResult = await authProvider.refresh(axiosInstance);
    if (refreshResult) access = authProvider.getAccessToken();
  }

  if (!withoutAccess) {
    axiosHeaders.Authorization = `Bearer ${access}`;
  }

  return instance({
    url,
    method,
    data,
    headers: axiosHeaders,
  });
};

export default axiosInstance;
