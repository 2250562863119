import { isImmutable, fromJS } from 'immutable';

import usersTypes from './usersTypes';

const initialState = {
  loading: false,
  users: [],
  pagination: {
    totalCount: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case usersTypes.USERS_START_ACTION:
      return state.set('loading', true);

    case usersTypes.GET_LIST_USERS:
      return state
        .set('users', fromJS(payload.users))
        .set('pagination', fromJS(payload.pagination))
        .set('loading', false);

    case usersTypes.UPDATE_USER:
      return state
        .set('users', state.get('users').set(payload.index, fromJS(payload.data)));

    case usersTypes.DELETE_USER:
      return state
        .set('users', state.get('users').delete(payload))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) - 1)
        .set('loading', false);

    case usersTypes.ADD_USER:
      return state
        .set('users', state.get('users').unshift(fromJS(payload)))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) + 1)
        .set('loading', false);

    case usersTypes.USERS_ACTION_FAILED:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};