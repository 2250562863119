import { isImmutable, fromJS, } from 'immutable';

import authTypes from './authTypes';

const initialState = {
  loading: false,
  isAuth: false,
  user: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.AUTH__START:
      return state.set('loading', true);

    case authTypes.AUTH__SUCCESS:
      return state
        .set('user', fromJS(action.payload))
        .set('isAuth', true)
        .set('loading', false);

    case authTypes.AUTH__FAILED:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
