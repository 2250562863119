import { isImmutable, fromJS } from 'immutable';

import jobsTypes from './jobsTypes';

const initialState = {
  loading: false,
  jobs: [],
  pagination: {
    totalCount: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case jobsTypes.JOBS_START_ACTION:
      return state.set('loading', true);

    case jobsTypes.GET_LIST_JOBS:
      return state
        .set('jobs', fromJS(payload.jobs))
        .set('pagination', fromJS(payload.pagination))
        .set('loading', false);

    case jobsTypes.UPDATE_JOB:
      return state
        .set('jobs', state.get('jobs').set(payload.index, fromJS(payload.data)));

    case jobsTypes.DELETE_JOB:
      return state
        .set('jobs', state.get('jobs').delete(payload))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) - 1)
        .set('loading', false);

    case jobsTypes.ADD_JOB:
      return state
        .set('jobs', state.get('jobs').unshift(fromJS(payload)))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) + 1)
        .set('loading', false);

    case jobsTypes.JOBS_ACTION_FAILED:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};