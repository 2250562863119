import React from 'react';

import { Form, Row, Col, Input } from 'antd';

const JobEditOrAddForm = ({ form, jobs, editingItem }) => {
  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="title"
            label="Job"
            initialValue={editingItem?.title}
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20 characters long',
            }]}
          >
            <Input
              placeholder="Please enter job"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="slug"
            label="Job key"
            initialValue={editingItem?.slug}
            rules={[{
              required: true,
              pattern: /^[\w\s._-]{2,2000}$/,
              message: 'The title should contain only English letters and be from 2 to 20 characters long',
            }]}
          >
            <Input
              placeholder="Please enter job key"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default JobEditOrAddForm;
