import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Button, Popconfirm, Select, Space } from 'antd';

import DefaultLayout from '../../components/layouts/DefaultLayout';
import DefaultTable from '../../components/common/tables/DefaultTable';
import TableHeader from '../../components/common/tables/TableHeader';
import AuthorAddOrEditForm from '../../components/authors/AuthorEditOrAddForm';
import DefaultPagination from '../../components/common/Pagination';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { getAuthors, deleteAuthor, updateAuthor, createAuthor } from '../../reducers/authors/actions';
import pageChanger from '../../utils/pageChanger';
import { findCategoryJobName } from '../../utils/getCategories';
import { PROGRAMMING_JOBS } from '../../constants/constants';

const AuthorsPage = () => {
  const dispatch = useDispatch();
  const authors = useSelector((state) => state.authors.get('authors'));
  const loading = useSelector((state) => state.authors.get('loading'));
  const totalCount = useSelector((state) => state.authors.getIn(['pagination', 'totalCount']));
  const pageCount = useSelector((state) => state.authors.getIn(['pagination', 'pageCount']));
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [editingObject, setEditingObject] = useState(null);

  useEffect(() => {
    dispatch(getAuthors({page: currentPage, limit}));
  }, [dispatch, currentPage, limit]);

  useEffect(() => {
    pageChanger(authors.size, getAuthors, currentPage, limit, pageCount, setCurrentPage, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authors.size]);

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const onUpdateAuthor = (id, index, values) => {
    dispatch(updateAuthor(id, index, values));
  };

  const onDeleteAuthor = (id, index) => {
    dispatch(deleteAuthor(id, index));
  };

  const onAddAuthor = (author) => {
    dispatch(createAuthor(author));
  };

  const onShowEditingWindow = (index, author) => {
    setEditingObject({ ...author, index });
  };

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'avatar',
      render: (_, item) => {
        return <Avatar size={64} src={item?.avatar} />;
      },
    },
      
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render: (_, item) => {
        return <>{item.firstName}&nbsp;{item.lastName}</>
      },
    },

    {
      title: 'Job',
      dataIndex: 'jobTitle',
      render: (_, item) => {
        return <>{`${findCategoryJobName(item?.jobTitle)}`}</>
      },
    },
      
    {
      title: 'Posts',
      dataIndex: 'countPosts',
      render: (_, item) => {
        return <>{item?.countPosts ? <>{item?.countPosts}</> : <>0</>}</>
      },
    }
  ];

  const actionColumn = {
    title: 'Actions',
    width: '10%',
    render: (_, item, index) => {
      return (
        <Space size="middle">
          <Button type="link" onClick={() => onShowEditingWindow(index, item)}>Edit</Button>
          <Popconfirm 
            title="Delete this item?" 
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => onDeleteAuthor(item.id, index)}
          >
          <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      );
    },
  };

  const jobsOptions = PROGRAMMING_JOBS.map((category) => {
    return <Select.Option value={category.key}>{category.title}</Select.Option>;
  });

  return (
    <DefaultLayout>
      <TableHeader 
        title="Authors"
        onAdd={onAddAuthor}
        onUpdate={onUpdateAuthor}
        editingItem={editingObject}
        setEditingItem={setEditingObject}
      >
        <AuthorAddOrEditForm 
          editingItem={editingObject} 
          jobs={jobsOptions}
        />
      </TableHeader>
      <DefaultTable
        data={authors}
        loading={loading}
        columns={columns}
        actionColumn={actionColumn}
      />
      <DefaultPagination
        totalCount={totalCount}
        currentPage={currentPage}
        onChange={onPageChange}
      />
    </DefaultLayout>
  );
};

export default AuthorsPage;