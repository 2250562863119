import { Auth, API } from "aws-amplify"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async createUser({userEmail, firstName, lastName, group}) {
    let apiName = "AdminQueries";
    let path = "/createUser";
    let data = {
      body: {
        username: userEmail,
        firstName,
        lastName,
        group
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const res = data.body
    await API.post(apiName, path, data)
    return res
  },

  async addUserToGroup({userEmail, group}) {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let data = {
      body: {
        username: userEmail,
        groupname: group,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, data)
  },

  async removeUserFromGroup({userEmail, group}) {
    let apiName = "AdminQueries";
    let path = "/removeUserFromGroup";
    let data = {
      body: {
        username: userEmail,
        groupname: group,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, data)
  },

  async getUserGroups({userEmail, limit, token}) {
    let apiName = "AdminQueries";
    let path = "/listGroupsForUser";
    let data = {
      queryStringParameters: {
        username: userEmail,
        limit, 
        token
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.get(apiName, path, data)
  },

  async getAllUsers({token, limit}) {
    let apiName = "AdminQueries";
    let path = "/listUsers";
    let data = {
      queryStringParameters: {
        token,
        limit
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.get(apiName, path, data)
  },

  async getAllUsersByGroup({group, limit, token}) {
    let apiName = "AdminQueries";
    let path = "/listUsersInGroup";
    let data = {
      queryStringParameters: {
        groupname: group,
        limit, 
        token
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.get(apiName, path, data)
  },

  async disableUser(userEmail) {
    let apiName = "AdminQueries";
    let path = "/disableUser";
    let data = {
      body: {
        username: userEmail,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, data)
  },

  async enableUser(userEmail) {
    let apiName = "AdminQueries";
    let path = "/enableUser";
    let data = {
      body: {
        username: userEmail,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, data)
  },

  async createGroup(group, precedence) {
    let apiName = "AdminQueries";
    let path = "/createGroup";
    let data = {
      body: {
        groupname: group,
        precedence
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, data)
  },

  async deleteGroup(group) {
    let apiName = "AdminQueries";
    let path = "/deleteGroup";
    let data = {
      body: {
        groupname: group,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.del(apiName, path, data)
  },

  async getAllGroups({ token, limit }) {
    let apiName = "AdminQueries";
    let path = "/listGroups";
    let data = {
      queryStringParameters: {
        token,
        limit
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.get(apiName, path, data)
  },

  async updateUser({userEmail, attributes}) {
    let apiName = "AdminQueries";
    let path = "/updateUser";
    let data = {
      body: {
        username: userEmail,
        attributes
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.put(apiName, path, data)
  },

  async deleteUser(userEmail) {
    let apiName = "AdminQueries";
    let path = "/deleteUser";
    let data = {
      body: {
        username: userEmail,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.del(apiName, path, data)
  },
};