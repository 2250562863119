import { isImmutable, fromJS } from 'immutable';

import authorsTypes from './autorsTypes';

const initialState = {
  loading: false,
  authors: [],
  pagination: {
    totalCount: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case authorsTypes.AUTHORS_START_ACTION:
      return state.set('loading', true);

    case authorsTypes.GET_LIST_AUTHORS:
      return state
        .set('authors', fromJS(payload.authors))
        .set('pagination', fromJS(payload.pagination))
        .set('loading', false);

    case authorsTypes.UPDATE_AUTHOR:
      return state
        .set('authors', state.get('authors').set(payload.index, fromJS(payload.data)));

    case authorsTypes.DELETE_AUTHOR:
      return state
        .set('authors', state.get('authors').delete(payload))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) - 1);

    case authorsTypes.ADD_AUTHOR:
      return state
        .set('authors', state.get('authors').unshift(fromJS(payload)))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) + 1);

    case authorsTypes.AUTHORS_ACTION_FAILED:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};