import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducersInit from './reducers';

export default (() => {
  let data = null;

  return (initialState = {}) => {
    if (data) return data;

    data = {
      store: createStore(
        reducersInit(),
        initialState,
        composeWithDevTools(applyMiddleware(thunk))
      ),
      // history,
    };

    return data;
  };
})();
