export const getRemovedAddedGroups = function ({oldGroups, newGroups}){
  try {
    const addedGroups = []
    const removedGroups = []
    oldGroups.forEach( oldGroup => {
      if (!newGroups.includes(oldGroup))
        removedGroups.push(oldGroup)
    })
    newGroups.forEach( newGroup => {
      if (!oldGroups.includes(newGroup))
        addedGroups.push(newGroup)
    })
    return {
			addedGroups,
			removedGroups
		}
	} catch (err) {
		console.log('ERROR GET REMUVED AND ADDED GROUPS - ', err.message)
		throw err
	}
}