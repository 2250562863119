import React from 'react';
import PropTypes from 'prop-types';

import { Pagination } from 'antd';

const DefaultPagination = ({ currentPage, totalCount, onChange }) => {
  return (
    <Pagination
      style={{ marginTop: 20, float: 'right' }}
      showSizeChanger
      defaultCurrent={1}
      defaultPageSize={20}
      current={currentPage}
      total={totalCount}
      pageSizeOptions={[5, 10, 15, 20]}
      onChange={(page, pageSize) => onChange(page, pageSize)}
    />
  );
};

DefaultPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DefaultPagination;