import { isImmutable, fromJS } from 'immutable';

import articlesTypes from './articlesTypes';

const initialState = {
  loading: false,
  articles: [],
  pagination: {
    totalCount: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case articlesTypes.ARTICLES_START_ACTION:
      return state.set('loading', true);

    case articlesTypes.GET_LIST_ARTICLES:
      return state
        .set('articles', fromJS(payload.articles))
        .set('pagination', fromJS(payload.pagination))
        .set('loading', false);

    case articlesTypes.UPDATE_ARTICLE:
      return state
        .set('articles', state.get('articles').set(payload.index, fromJS(payload.data)));

    case articlesTypes.DELETE_ARTICLE:
      return state
        .set('articles', state.get('articles').delete(payload))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) - 1);

    case articlesTypes.ADD_ARTICLE:
      return state
        .set('articles', state.get('articles').unshift(fromJS(payload)))
        .setIn(['pagination', 'totalCount'], state.getIn(['pagination', 'totalCount']) + 1);

    case articlesTypes.ARTICLES_ACTION_FAILED:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};