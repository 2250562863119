import React from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux';
// import Message from '../Message';
// import SmallSpin from '../../common/Spin';

const LoginForm = ({ forgotPassword, onFinish }) => {
  // const emailSent = useSelector((state) => state.passwords.get('emailSent'));
  // const loading = useSelector((state) => state.passwords.get('loading'));
  const buttonName = forgotPassword ? 'Send' : 'Log in';

  // if (forgotPassword && loading) return <SmallSpin />;

  // if (forgotPassword && emailSent) return <Message />;
    
  return (
    <Form
      className="login-page__form"
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
        ]}
      >
        <Input
          autoComplete="on"
          prefix={<MailOutlined className="login-page__form-icon" />}
          placeholder="Email"
          size="large"
        />
      </Form.Item>
      {!forgotPassword ? (
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
        >
          <Input.Password
            autoComplete="on"
            prefix={<LockOutlined className="login-page__form-icon" />}
            placeholder="Password"
            size="large"
          />
        </Form.Item>
      ) : null }
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          block
        >
          {buttonName}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;