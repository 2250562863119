import { message } from 'antd';

import axios from '../../utils/axios';
import filesTypes from './filesTypes';
import getFormData from '../../utils/getFormData';

export const getFiles = (page = 1, limit = 20) => async (dispatch) => {
  dispatch({ type: filesTypes.FILES_START_ACTION });

  const { data, status } = await axios({
    method: 'get',
    url: `/files?page=${page}&limit=${limit}`,
  });

  if (status === 200) {
    return dispatch({
      type: filesTypes.GET_LIST_FILES,
      payload: {
        files: data.files,
        pagination: data.pagination,
      },
    });
  }

  message.error(data.message);
  return dispatch({ type: filesTypes.FILES_ACTION_FAILED });
};

export const deleteFile = (id, index) => async (dispatch) => {
  const { data, status } = await axios({
    method: 'delete',
    url: `/files/${id}`,
  });

  if (status === 200 || status === 404) {
    return dispatch({
      type: filesTypes.DELETE_FILE,
      payload: index,
    });
  }

  message.error(data.message);
};

export const createFile = (file) => async (dispatch) => {
  const formData = getFormData(file);

  const { data, status } = await axios({
    method: 'post',
    url: '/files',
    data: formData,
  });

  if (status === 200) {
    return dispatch({
      type: filesTypes.ADD_FILE,
      payload: data.file,
    });
  }

  message.error(data.message);
};

export const updateFile = (id, index, values) => async (dispatch) => {
  const { data, status } = await axios({
    method: 'patch',
    url: `/files/${id}`,
    data: values,
  });

  if (status === 200) {
    return dispatch({
      type: filesTypes.UPDATE_FILE,
      payload: { index, data: data.updatedFile }
    });
  }

  message.error(data.message);
};
