import React from 'react';
import { Provider } from 'react-redux';
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import Routers from './Routers';
import init from './init';

import awsExports from './aws-exports';
import 'antd/dist/antd.css';

Amplify.configure({
  ...awsExports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
})
function App({ store }) {
  return (
    <Provider store={store}>
      <Routers />
    </Provider>
  );
}

export default init(App);
