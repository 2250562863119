import { combineReducers } from 'redux';

import auth from './auth';
import articles from './articles';
// import tags from './tags';
// import seo from './seo';
// import robots from './robots';
// import categories from './categories';
import users from './users';
import jobs from './jobs';
// import passwords from './passwords';
// import files from './files';
// import works from './works';
// import industries from './industries';
// import services from './services';
import authors from './authors';
// import medias from './media';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return combineReducers({
    auth,
    articles,
    // robots,
    // tags,
    // seo,
    // categories,
    jobs,
    users,
    // passwords,
    // files,
    // works,
    // industries,
    // services,
    authors,
    // medias,
  });
};
