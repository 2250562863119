import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Table, Form, Typography, Space, Popconfirm
} from 'antd';

import EditableCell from './EditableCell';

const DefaultTable = ({ data, loading, columns, actionColumn, onDelete, onEdit }) => {
  const userId = useSelector((state) => state.auth.getIn(['user', 'id']));

  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState('');

  const onCancel = () => {
    setEditingItem('');
  };

  const setFields = (item) => {
    form.setFieldsValue({
      ...item
    });
    setEditingItem(item._id);
  };

  const onSave = async (itemId, index) => {
    const values = await form.validateFields();
    onEdit(itemId, index, values);
    onCancel();
  };

  const defaultActionColumn = {
    title: 'Actions',
    dataIndex: 'actions',
    width: '10%',
    render: (_, item, index) => {
      if (userId === item._id) {
        return (
          <Space size="middle">
            <Typography.Link disabled="true">Edit</Typography.Link>
            <Typography.Link disabled="true">Delete</Typography.Link>
          </Space>
        );
      } 
      const editable = item._id === editingItem;
      return (
        editable ? (
          <Space size="middle">
            <div onClick={() => onCancel()}>Cancel</div>
            <div onClick={() => onSave(item._id, index)}>Save</div>
          </Space>
        )
          : (
            <Space size="middle">
              <Typography.Link disabled={editingItem !== ''} onClick={() => setFields(item)}>
                Edit
              </Typography.Link>
              <Popconfirm title="Delete this item?" onConfirm={() => onDelete(item._id, index)}>
                <Typography.Link disabled={editingItem !== ''}>
                  Delete
                </Typography.Link>
              </Popconfirm>
            </Space>
          )
      );
    },
  };

  const mergedColumns = [...columns, actionColumn || defaultActionColumn].map((col) => {
    return {
      ...col,
      onCell: (item) => ({
        item,
        title: col.title,
        dataIndex: col.dataIndex,
        component: col.component,
        rules: col.rules,
        editable:
            col.component
            && item._id === editingItem,
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        pagination={false}
        dataSource={data.toJS()}
        columns={mergedColumns}
        loading={loading}
        scroll={{ x: true }}
        rowKey={row=>row.id}
      />
    </Form>
  );
};

DefaultTable.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  actionColumn: PropTypes.object,
};

DefaultTable.defaultProps = {
  actionColumn: null,
  onDelete: null,
  onEdit: null,
};

export default DefaultTable;
