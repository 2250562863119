import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Popconfirm, Space } from 'antd';

import DefaultLayout from '../../components/layouts/DefaultLayout';
import DefaultTable from '../../components/common/tables/DefaultTable';
import TableHeader from '../../components/common/tables/TableHeader';
import JobAddOrEditForm from '../../components/jobs/JobEditOrAddForm';
import DefaultPagination from '../../components/common/Pagination';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { getJobs, deleteJob, updateJob, createJob } from '../../reducers/jobs/actions';
import pageChanger from '../../utils/pageChanger';

const JobsPage = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.jobs.get('jobs'));
  const loading = useSelector((state) => state.jobs.get('loading'));
  const totalCount = useSelector((state) => state.jobs.getIn(['pagination', 'totalCount']));
  const pageCount = useSelector((state) => state.jobs.getIn(['pagination', 'pageCount']));
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [editingObject, setEditingObject] = useState(null);

  useEffect(() => {
    dispatch(getJobs({page: currentPage, limit}));
  }, [dispatch, currentPage, limit]);

  useEffect(() => {
    pageChanger(jobs.size, getJobs, currentPage, limit, pageCount, setCurrentPage, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs.size]);

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const onUpdateJob = (id, index, values) => {
    dispatch(updateJob(id, index, values));
  };

  const onDeleteJob = (id, index) => {
    dispatch(deleteJob(id, index));
  };

  const onAddJob = (Job) => {
    dispatch(createJob(Job));
  };

  const onShowEditingWindow = (index, Job) => {
    setEditingObject({ ...Job, index });
  };

  const columns = [
    {
      title: 'Job',
      dataIndex: 'title',
      render: (_, item) => {
        return <>{item.title}</>
      },
    },
      
    {
      title: 'Job key',
      dataIndex: 'slug',
      render: (_, item) => {
        return <>{item.slug}</>
      },
    }
  ];

  const actionColumn = {
    title: 'Jobs',
    width: '10%',
    render: (_, item, index) => {
      return (
        <Space size="middle">
          <Button type="link" onClick={() => onShowEditingWindow(index, item)}>Edit</Button>
          <Popconfirm 
            title="Delete this item?" 
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => onDeleteJob(item.id, index)}
          >
          <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      );
    },
  };

  return (
    <DefaultLayout>
      <TableHeader 
        title="Jobs"
        onAdd={onAddJob}
        onUpdate={onUpdateJob}
        editingItem={editingObject}
        setEditingItem={setEditingObject}
      >
        <JobAddOrEditForm 
          editingItem={editingObject} 
        />
      </TableHeader>
      <DefaultTable
        data={jobs}
        loading={loading}
        columns={columns}
        actionColumn={actionColumn}
      />
      <DefaultPagination
        totalCount={totalCount}
        currentPage={currentPage}
        onChange={onPageChange}
      />
    </DefaultLayout>
  );
};

export default JobsPage;